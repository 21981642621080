<template>
    <div class="main main-scroll btm-menu">

        <div class="main-sidebar">
            <div class="main-sidebar__head">
                <h4 class="title">MERKLISTEN</h4>
                <p>Merklisten bündeln Angebote zu bestimmten Themen</p>
            </div>

            <div class="main-sidebar__filter none">
                <keyword-search class="mb40" @searchWordChanged="searchWordChanged"/>
            </div>
        </div>

        <div class="main-content">

            <div class="section">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 d-lg-none">
                            <h5 class="mb10">Merklisten</h5>
                            <p>Merklisten bündeln Angebote zu bestimmten Themen.</p>
                        </div>
                        <div class="col-12 d-none d-lg-block">
                            <h5 class="mb20">{{count}} Ergebnisse</h5>
                        </div>
                    </div>
                    <div class="row" v-if="resultsToDisplay!==null && resultsToDisplay.length>0">
                        <div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in resultsToDisplay" :key="index">
                            <merkliste-card :item="item" :autoWidth="true"/>
                        </div>
                    </div>
                    <div v-else class="search-no-results">
                        <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                        <h5 class="mt20">Keine Ergebnisse gefunden</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { showLoader, hideLoader } from '@/utils/helpers';

    export default {
        name: "Merkliste",
        components: {
           MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
           KeywordSearch: () => import('@/components/controls/KeywordSearch'),
       },
       data() {
        return {
            merklisten: null,
            resultsToDisplay: null,
        }
    },
    computed: {
        count(){
            if(this.resultsToDisplay!==null){
                return this.resultsToDisplay.length;
            }
            return 0;
        },
        searchData(){
            if(this.merklisten !== null){
              var gna = [];
              for(var i = 0; i < this.merklisten.length; i++){
                gna.push(JSON.stringify(this.merklisten[i]).toLowerCase());
            }
            return gna;
        }
        return null;
    },
},
created(){
    this.getMerklisten();
},
methods: {
 showLoader, 
 hideLoader,
 searchWordChanged(word){
    var contentLoader = this.showLoader(contentLoader);
    if(this.word!==''){
        word = word.toLowerCase();
        if(this.searchData!==null && this.searchData.length>0){
            var results = [];
            for(var i = 0; i<this.searchData.length; i++){
                if(this.searchData[i].includes(word)){
                    results.push(this.merklisten[i]);
                }
            }
            contentLoader = this.hideLoader(contentLoader);
            this.resultsToDisplay = results;
            return;
        }
        contentLoader = this.hideLoader(contentLoader);
    }
    this.resultsToDisplay = this.merklisten;
},
getMerklisten() {
    var contentLoader = this.showLoader(contentLoader);
    this.$store.dispatch('loadCollections').then(response => {
        this.merklisten = this.extractMerklisten(response);
        this.resultsToDisplay = this.merklisten;
    })
    .finally(() => {
        contentLoader = this.hideLoader(contentLoader);
    });
},
extractMerklisten(data){
    if(data !== null){
      var merklisten = [];
      for(var i = 0; i < data.length; i++){
        var contents = data[i].contents;
        if(contents !== null && contents.length>=3){
            merklisten.push(data[i]);
        }
    }  
    return merklisten;
}
return null;
}
},
};
</script>
<style lang=scss scoped>
    
    .col-lg-4 {

        @media (min-width: 992px) {
            flex: 0 0 100%;
            min-width: 100%;
        }

        @media (min-width: 1050px) {
            flex: 0 0 50%;
            min-width: 50%;
        }

        @media (min-width: 1350px) {
          flex: 0 0 33%;
          min-width: 33%;
      }
  }
  

  .search-no-results{
    margin-top: 40px;
    width: 100%; 
    text-align: center;

    img{
        width: 110px;
    }  
}

.main {
    margin-bottom: 0px;
}

</style>